export const albums_data = {
    "1969-1974 Schulzeiten und danach": {
        "name": "1969-1974 Schulzeiten und danach",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x496_1.LT_1969.jpg",
                "faces": {},
                "height": 634,
                "name": "1.LT_1969.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/639x634_1.LT_1969.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x496_1.LT_1969.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/639x634_1.LT_1969.jpg 639w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/639x634_1.LT_1969.jpg 639w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/639x634_1.LT_1969.jpg 639w"
                ],
                "width": 639
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x358_Elmar-Wieselburg-Jahrgang-1-1969.jpg",
                "faces": {},
                "height": 1572,
                "name": "Elmar-Wieselburg-Jahrgang-1-1969.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1147_Elmar-Wieselburg-Jahrgang-1-1969.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x358_Elmar-Wieselburg-Jahrgang-1-1969.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x573_Elmar-Wieselburg-Jahrgang-1-1969.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x734_Elmar-Wieselburg-Jahrgang-1-1969.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1147_Elmar-Wieselburg-Jahrgang-1-1969.jpg 1600w"
                ],
                "width": 2191
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x720_Herman-Kr%C3%A4han.jpg",
                "faces": {},
                "height": 1516,
                "name": "Herman-Kr\u00e4han.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1052x1516_Herman-Kr%C3%A4han.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x720_Herman-Kr%C3%A4han.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1152_Herman-Kr%C3%A4han.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1475_Herman-Kr%C3%A4han.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1052x1516_Herman-Kr%C3%A4han.jpg 1052w"
                ],
                "width": 1052
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200215_143712.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200215_143712.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200215_143712.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200215_143712.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200215_143712.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200215_143712.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200215_143712.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200215_144604.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200215_144604.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200215_144604.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200215_144604.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200215_144604.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200215_144604.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200215_144604.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200215_145225.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200215_145225.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200215_145225.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200215_145225.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200215_145225.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200215_145225.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200215_145225.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x294_IMG_20200215_151859.jpg",
                "faces": {},
                "height": 1129,
                "name": "IMG_20200215_151859.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x940_IMG_20200215_151859.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x294_IMG_20200215_151859.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x470_IMG_20200215_151859.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x602_IMG_20200215_151859.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x940_IMG_20200215_151859.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_083458.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_083458.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_083458.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_083458.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_083458.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_083458.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_083458.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_083656.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_083656.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_083656.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_083656.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_083656.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_083656.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_083656.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_083719.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_083719.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_083719.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_083719.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_083719.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_083719.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_083719.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_084033.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_084033.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_084033.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_084033.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_084033.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_084033.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_084033.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_084646.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_084646.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_084646.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_084646.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_084646.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_084646.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_084646.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_084705.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_084705.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_084705.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_084705.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_084705.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_084705.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_084705.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_085634.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_085634.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_085634.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_085634.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_085634.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_085634.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_085634.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_085649.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_085649.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_085649.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_085649.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_085649.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_085649.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_085649.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_085920.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_085920.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_085920.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_085920.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_085920.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_085920.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_085920.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_090149.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_090149.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_090149.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_090149.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_090149.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_090149.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_090149.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_090248.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_090248.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_090248.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_090248.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_090248.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_090248.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_090248.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_090527.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_090527.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_090527.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_090527.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_090527.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_090527.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_090527.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_090937.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_090937.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_090937.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_090937.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_090937.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_090937.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_090937.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_091141.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_091141.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_091141.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_091141.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_091141.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_091141.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_091141.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_091149.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_091149.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_091149.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_091149.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_091149.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_091149.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_091149.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_092537.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_092537.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_092537.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_092537.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_092537.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_092537.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_092537.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_092703.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_092703.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_092703.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_092703.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_092703.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_092703.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_092703.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_092756.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_092756.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_092756.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_092756.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_092756.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_092756.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_092756.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_092927.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_092927.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_092927.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_092927.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_092927.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_092927.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_092927.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_093359.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_093359.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_093359.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_093359.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_093359.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_093359.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_093359.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_093419.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_093419.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_093419.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_093419.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_093419.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_093419.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_093419.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_093449.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_093449.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_093449.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_093449.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_093449.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_093449.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_093449.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_093519.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200801_093519.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_093519.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200801_093519.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200801_093519.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200801_093519.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200801_093519.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_093620.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200801_093620.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_093620.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200801_093620.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200801_093620.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200801_093620.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200801_093620.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200802_135846.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200802_135846.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200802_135846.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200802_135846.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200802_135846.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200802_135846.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200802_135846.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200802_141644.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200802_141644.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200802_141644.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200802_141644.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200802_141644.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200802_141644.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200802_141644.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200802_142316.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200802_142316.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200802_142316.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200802_142316.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200802_142316.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200802_142316.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200802_142316.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200802_142425.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200802_142425.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200802_142425.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200802_142425.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200802_142425.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200802_142425.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200802_142425.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200802_142431.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200802_142431.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200802_142431.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200802_142431.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200802_142431.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200802_142431.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200802_142431.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180033.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180033.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180033.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180033.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180033.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180033.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180033.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180154.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180154.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180154.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180154.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180154.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180154.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180154.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180209.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200905_180209.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180209.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180209.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200905_180209.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200905_180209.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180209.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180223.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180223.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180223.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180223.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180223.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180223.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180223.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180238.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200905_180238.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180238.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180238.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200905_180238.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200905_180238.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180238.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180252.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180252.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180252.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180252.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180252.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180252.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180252.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180305.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200905_180305.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180305.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180305.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200905_180305.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200905_180305.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180305.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180326.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180326.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180326.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180326.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180326.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180326.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180326.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180618.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200905_180618.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180618.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_180618.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200905_180618.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200905_180618.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_180618.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180736.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180736.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180736.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180736.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180736.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180736.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180736.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180811.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180811.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180811.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180811.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180811.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180811.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180811.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180831.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_180831.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180831.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_180831.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_180831.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_180831.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_180831.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_181002.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200905_181002.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_181002.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_181002.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200905_181002.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200905_181002.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_181002.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181309.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_181309.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181309.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181309.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_181309.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_181309.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181309.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181333.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_181333.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181333.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181333.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_181333.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_181333.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181333.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_181358.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200905_181358.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_181358.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x665_IMG_20200905_181358.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x1065_IMG_20200905_181358.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x1363_IMG_20200905_181358.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1442x1920_IMG_20200905_181358.jpg 1442w"
                ],
                "width": 1442
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181507.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_181507.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181507.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181507.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_181507.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_181507.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181507.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181534.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_181534.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181534.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_181534.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_181534.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_181534.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_181534.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_182140.jpg",
                "faces": {},
                "height": 1442,
                "name": "IMG_20200905_182140.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_182140.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x375_IMG_20200905_182140.jpg 500w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/800x600_IMG_20200905_182140.jpg 800w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1024x769_IMG_20200905_182140.jpg 1024w",
                    "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/1600x1201_IMG_20200905_182140.jpg 1600w"
                ],
                "width": 1920
            }
        ],
        "src": "/static/_gallery/albums/1969-1974%20Schulzeiten%20und%20danach/500x496_1.LT_1969.jpg"
    },
    "1974 Maturazeitung": {
        "name": "1974 Maturazeitung",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x706_LT1969-1974_01.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_01.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1240x1753_LT1969-1974_01.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x706_LT1969-1974_01.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x1130_LT1969-1974_01.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x1447_LT1969-1974_01.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1240x1753_LT1969-1974_01.jpg 1240w"
                ],
                "width": 1240
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_02.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_02.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_02.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_02.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_02.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_02.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_02.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_03.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_03.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_03.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_03.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_03.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_03.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_03.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_04.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_04.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_04.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_04.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_04.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_04.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_04.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_05.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_05.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_05.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_05.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_05.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_05.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_05.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_06.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_06.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_06.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_06.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_06.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_06.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_06.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_07.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_07.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_07.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_07.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_07.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_07.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_07.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_08.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_08.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_08.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_08.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_08.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_08.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_08.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_09.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_09.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_09.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_09.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_09.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_09.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_09.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_10.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_10.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_10.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_10.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_10.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_10.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_10.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_11.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_11.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_11.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_11.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_11.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_11.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_11.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_12.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_12.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_12.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_12.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_12.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_12.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_12.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_13.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_13.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_13.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_13.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_13.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_13.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_13.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_14.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_14.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_14.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_14.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_14.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_14.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_14.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_15.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_15.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_15.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_15.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_15.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_15.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_15.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_16.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_16.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_16.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_16.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_16.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_16.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_16.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_17.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_17.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_17.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_17.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_17.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_17.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_17.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_18.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_18.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_18.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_18.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_18.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_18.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_18.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_19.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_19.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_19.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_19.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_19.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_19.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_19.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_20.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_20.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_20.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_20.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_20.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_20.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_20.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_21.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_21.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_21.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_21.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_21.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_21.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_21.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_22.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_22.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_22.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_22.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_22.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_22.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_22.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_23.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_23.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_23.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_23.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_23.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_23.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_23.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_24.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_24.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_24.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_24.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_24.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_24.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_24.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_25.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_25.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_25.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_25.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_25.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_25.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_25.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_26.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_26.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_26.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_26.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_26.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_26.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_26.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_27.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_27.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_27.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_27.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_27.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_27.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_27.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_28.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_28.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_28.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_28.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_28.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_28.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_28.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_29.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_29.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_29.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_29.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_29.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_29.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_30.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_30.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_30.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_30.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_30.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_30.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_30.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_31.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_31.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_31.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_31.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_31.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_31.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_31.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_32.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_32.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_32.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_32.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_32.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_32.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_32.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_33.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_33.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_33.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_33.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_33.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_33.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_33.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_34.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_34.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_34.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_34.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_34.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_34.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_34.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_35.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_35.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_35.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_35.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_35.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_35.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_35.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_36.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_36.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_36.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_36.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_36.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_36.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_36.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_37.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_37.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_37.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_37.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_37.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_37.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_37.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_38.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_38.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_38.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_38.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_38.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_38.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_38.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_39.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_39.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_39.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_39.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_39.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_39.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_39.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_40.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_40.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_40.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_40.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_40.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_40.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_40.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_41.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_41.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_41.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_41.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_41.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_41.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_41.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_42.jpg",
                "faces": {},
                "height": 1753,
                "name": "LT1969-1974_42.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_42.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x353_LT1969-1974_42.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x565_LT1969-1974_42.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x723_LT1969-1974_42.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x1130_LT1969-1974_42.jpg 1600w"
                ],
                "width": 2480
            },
            {
                "_thumb": "/static/_gallery/albums/1974%20Maturazeitung/500x250_MATURA_LT_1974.jpg",
                "faces": {},
                "height": 1621,
                "name": "MATURA_LT_1974.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/1974%20Maturazeitung/1600x800_MATURA_LT_1974.jpg",
                "srcSet": [
                    "/static/_gallery/albums/1974%20Maturazeitung/500x250_MATURA_LT_1974.jpg 500w",
                    "/static/_gallery/albums/1974%20Maturazeitung/800x400_MATURA_LT_1974.jpg 800w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1024x512_MATURA_LT_1974.jpg 1024w",
                    "/static/_gallery/albums/1974%20Maturazeitung/1600x800_MATURA_LT_1974.jpg 1600w"
                ],
                "width": 3241
            }
        ],
        "src": "/static/_gallery/albums/1974%20Maturazeitung/500x706_LT1969-1974_01.jpg"
    },
    "2004 Klassentreffen 30 Jahre P\u00f6ttelsdorf": {
        "name": "2004 Klassentreffen 30 Jahre P\u00f6ttelsdorf",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20001.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 001.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20001.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20001.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20001.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20001.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20001.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20002.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 002.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20002.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20002.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20002.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20002.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20002.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20003.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 003.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20003.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20003.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20003.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20003.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20003.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20004.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 004.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20004.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20004.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20004.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20004.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20004.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20005.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 005.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20005.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20005.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20005.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20005.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20005.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20006.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 006.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20006.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20006.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20006.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20006.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20006.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20007.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 007.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20007.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20007.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20007.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20007.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20007.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20009.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 009.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20009.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20009.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20009.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20009.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20009.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20011.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 011.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20011.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20011.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20011.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20011.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20011.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20012.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 012.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20012.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20012.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20012.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20012.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20012.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20013.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 013.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20013.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20013.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20013.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20013.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20013.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20014.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 014.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20014.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20014.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20014.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20014.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20014.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20017.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 017.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20017.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20017.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20017.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20017.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20017.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20018.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 018.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20018.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20018.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20018.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20018.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20018.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20020.jpg",
                "faces": {},
                "height": 800,
                "name": "Maturatreff30Jahre 020.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20020.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20020.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20020.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20020.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20020.jpg 600w"
                ],
                "width": 600
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20021.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 021.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20021.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20021.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20021.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20021.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20021.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20023.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 023.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20023.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20023.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20023.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20023.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20023.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20024.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 024.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20024.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20024.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20024.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20024.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20024.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20025.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 025.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20025.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20025.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20025.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20025.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20025.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20026.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 026.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20026.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20026.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20026.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20026.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20026.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20028.jpg",
                "faces": {},
                "height": 800,
                "name": "Maturatreff30Jahre 028.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20028.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20028.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20028.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20028.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20028.jpg 600w"
                ],
                "width": 600
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20029.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 029.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20029.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20029.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20029.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20029.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20029.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20030.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 030.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20030.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20030.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20030.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20030.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20030.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20031.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 031.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20031.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20031.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20031.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20031.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20031.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20032.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 032.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20032.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20032.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20032.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20032.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20032.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20033.jpg",
                "faces": {},
                "height": 1944,
                "name": "Maturatreff30Jahre 033.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/1600x1200_Maturatreff30Jahre%20033.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20033.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20033.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/1024x768_Maturatreff30Jahre%20033.jpg 1024w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/1600x1200_Maturatreff30Jahre%20033.jpg 1600w"
                ],
                "width": 2592
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20034.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre 034.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20034.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20034.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20034.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20034.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20034.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%281%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (1).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%281%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%281%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%281%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%281%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%281%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2810%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (10).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2810%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2810%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2810%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2810%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2810%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2811%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (11).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2811%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2811%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2811%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2811%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2811%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2812%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (12).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2812%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2812%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2812%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2812%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2812%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2813%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (13).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2813%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2813%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2813%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2813%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2813%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20Ernst%20%2814%29.jpg",
                "faces": {},
                "height": 800,
                "name": "Maturatreff30Jahre Ernst (14).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2814%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20Ernst%20%2814%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2814%29.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2814%29.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2814%29.jpg 600w"
                ],
                "width": 600
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2815%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (15).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2815%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2815%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2815%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2815%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2815%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2816%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (16).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2816%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2816%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2816%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2816%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2816%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2817%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (17).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2817%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2817%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2817%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2817%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2817%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20Ernst%20%2818%29.jpg",
                "faces": {},
                "height": 800,
                "name": "Maturatreff30Jahre Ernst (18).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2818%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20Ernst%20%2818%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2818%29.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2818%29.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2818%29.jpg 600w"
                ],
                "width": 600
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2819%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (19).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2819%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2819%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2819%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2819%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2819%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%282%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (2).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%282%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%282%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%282%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%282%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%282%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2820%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (20).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2820%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2820%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2820%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2820%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2820%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2821%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (21).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2821%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2821%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2821%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2821%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2821%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2822%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (22).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2822%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2822%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2822%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2822%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2822%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2823%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (23).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2823%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2823%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2823%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2823%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2823%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2824%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (24).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2824%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2824%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2824%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2824%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2824%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2825%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (25).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2825%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2825%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2825%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2825%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2825%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2826%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (26).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2826%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2826%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2826%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2826%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2826%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2827%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (27).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2827%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2827%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2827%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2827%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2827%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2828%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (28).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2828%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2828%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2828%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2828%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2828%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2829%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (29).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2829%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2829%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2829%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2829%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2829%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%283%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (3).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%283%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%283%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%283%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%283%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%283%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2830%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (30).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2830%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2830%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2830%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2830%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2830%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2831%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (31).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2831%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2831%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2831%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2831%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2831%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20Ernst%20%2832%29.jpg",
                "faces": {},
                "height": 800,
                "name": "Maturatreff30Jahre Ernst (32).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2832%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x666_Maturatreff30Jahre%20Ernst%20%2832%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2832%29.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2832%29.jpg 600w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/600x800_Maturatreff30Jahre%20Ernst%20%2832%29.jpg 600w"
                ],
                "width": 600
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2833%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (33).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2833%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2833%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2833%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2833%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2833%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2834%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (34).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2834%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2834%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2834%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2834%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2834%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2835%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (35).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2835%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2835%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2835%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2835%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2835%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2836%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (36).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2836%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2836%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2836%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2836%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2836%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2837%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (37).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2837%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2837%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2837%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2837%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2837%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2838%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (38).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2838%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2838%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2838%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2838%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2838%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2839%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (39).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2839%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2839%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2839%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2839%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2839%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%284%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (4).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%284%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%284%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%284%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%284%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%284%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2840%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (40).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2840%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2840%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2840%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2840%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2840%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2841%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (41).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2841%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2841%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2841%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2841%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2841%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2842%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (42).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2842%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2842%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2842%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2842%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2842%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2843%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (43).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2843%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2843%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2843%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2843%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2843%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2844%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (44).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2844%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2844%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2844%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2844%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2844%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2845%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (45).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2845%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2845%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2845%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2845%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2845%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2846%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (46).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2846%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%2846%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2846%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2846%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%2846%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%285%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (5).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%285%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%285%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%285%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%285%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%285%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%286%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (6).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%286%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%286%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%286%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%286%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%286%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%287%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (7).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%287%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%287%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%287%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%287%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%287%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%288%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (8).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%288%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%288%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%288%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%288%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%288%29.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%289%29.jpg",
                "faces": {},
                "height": 600,
                "name": "Maturatreff30Jahre Ernst (9).jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%289%29.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20Ernst%20%289%29.jpg 500w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%289%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%289%29.jpg 800w",
                    "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/800x600_Maturatreff30Jahre%20Ernst%20%289%29.jpg 800w"
                ],
                "width": 800
            }
        ],
        "src": "/static/_gallery/albums/2004%20Klassentreffen%2030%20Jahre%20P%C3%B6ttelsdorf/500x375_Maturatreff30Jahre%20001.jpg"
    },
    "2006 Klassentreffen Gro\u00dfglockner": {
        "name": "2006 Klassentreffen Gro\u00dfglockner",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_001.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_001.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_001.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_001.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_001.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_001.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_001.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_002.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_002.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_002.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_002.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_002.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_002.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_002.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_003.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_003.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_003.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_003.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_003.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_003.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_003.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_004.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_004.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_004.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_004.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_004.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_004.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_004.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_005.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_005.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_005.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_005.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_005.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_005.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_005.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_006.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_006.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_006.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_006.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_006.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_006.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_006.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_007.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_007.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_007.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_007.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_007.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_007.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_007.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_008.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_008.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_008.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_008.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_008.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_008.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_008.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_009.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_009.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_009.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_009.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_009.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_009.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_009.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_010.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_010.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_010.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_010.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_010.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_010.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_010.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_011.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_011.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_011.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_011.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_011.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_011.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_011.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_012.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_012.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_012.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_012.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_012.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_012.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_012.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_013.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_013.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_013.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_013.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_013.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_013.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_013.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_014.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_014.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_014.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_014.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_014.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_014.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_014.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_015.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_015.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_015.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_015.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_015.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_015.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_015.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_016.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_016.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_016.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_016.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_016.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_016.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_016.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_017.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_017.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_017.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_017.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_017.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_017.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_017.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_018.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_018.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_018.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_018.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_018.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_018.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_018.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_019.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_019.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_019.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_019.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_019.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_019.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_019.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_020.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_020.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_020.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_020.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_020.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_020.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_020.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_021.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_021.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_021.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_021.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_021.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_021.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_021.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_022.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_022.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_022.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_022.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_022.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_022.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_022.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_023.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_023.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_023.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_023.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_023.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_023.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_023.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_024.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_024.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_024.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_024.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_024.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_024.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_024.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_025.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_025.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_025.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_025.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_025.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_025.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_025.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_026.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_026.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_026.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_026.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_026.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_026.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_026.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_027.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_027.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_027.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_027.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_027.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_027.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_027.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_028.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_028.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_028.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_028.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_028.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_028.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_028.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_029.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_029.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_029.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_029.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_029.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_029.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_029.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_030.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_030.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_030.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_030.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_030.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_030.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_030.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_031.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_031.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_031.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_031.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_031.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_031.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_031.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_032.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_032.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_032.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_032.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_032.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_032.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_032.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_033.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_033.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_033.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_033.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_033.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_033.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_033.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_034.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_034.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_034.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_034.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_034.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_034.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_034.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_035.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_035.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_035.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_035.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_035.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_035.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_035.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_036.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_036.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_036.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_036.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_036.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_036.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_036.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_037.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_037.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_037.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_037.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_037.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_037.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_037.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_038.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_038.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_038.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_038.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_038.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_038.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_038.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_039.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_039.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_039.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_039.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_039.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_039.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_039.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_040.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_040.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_040.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_040.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_040.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_040.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_040.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_041.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_041.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_041.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_041.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_041.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_041.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_041.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_042.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_042.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_042.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_042.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_042.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_042.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_042.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_043.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_043.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_043.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_043.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_043.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_043.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_043.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_044.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_044.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_044.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_044.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_044.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_044.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_044.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_045.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_045.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_045.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_045.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_045.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_045.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_045.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_046.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_046.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_046.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_046.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_046.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_046.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_046.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_047.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_047.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_047.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_047.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_047.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_047.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_047.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_048.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_048.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_048.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_048.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_048.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_048.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_048.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_049.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_049.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_049.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_049.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_049.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_049.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_049.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_050.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_050.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_050.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_050.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_050.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_050.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_050.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_051.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_051.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_051.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_051.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_051.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_051.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_051.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_052.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_052.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_052.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_052.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_052.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_052.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_052.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_053.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_053.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_053.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_053.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_053.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_053.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_053.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_054.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_054.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_054.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_054.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_054.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_054.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_054.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_055.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_055.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_055.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_055.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_055.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_055.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_055.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_056.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_056.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_056.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_056.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_056.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_056.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_056.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_057.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_057.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_057.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_057.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_057.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_057.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_057.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_058.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_058.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_058.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_058.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_058.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_058.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_058.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_059.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_059.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_059.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_059.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_059.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_059.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_059.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_060.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_060.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_060.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_060.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_060.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_060.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_060.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_061.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_061.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_061.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_061.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_061.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_061.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_061.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_062.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_062.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_062.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_062.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_062.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_062.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_062.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_063.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_063.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_063.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_063.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_063.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_063.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_063.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_064.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_064.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_064.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_064.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_064.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_064.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_064.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_065.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_065.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_065.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_065.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_065.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_065.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_065.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_066.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_066.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_066.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_066.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_066.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_066.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_066.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_067.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_067.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_067.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_067.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_067.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_067.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_067.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_068.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_068.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_068.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_068.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_068.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_068.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_068.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_069.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_069.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_069.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_069.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_069.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_069.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_069.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_070.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_070.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_070.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_070.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_070.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_070.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_070.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_071.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_071.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_071.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_071.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_071.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_071.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_071.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_072.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_072.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_072.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_072.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_072.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_072.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_072.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_073.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_073.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_073.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_073.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_073.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_073.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_073.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_074.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_074.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_074.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_074.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_074.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_074.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_074.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_075.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_075.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_075.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_075.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_075.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_075.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_075.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_076.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_076.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_076.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_076.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_076.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_076.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_076.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_077.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_077.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_077.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_077.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_077.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_077.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_077.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_078.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_078.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_078.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_078.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_078.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_078.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_078.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_079.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_079.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_079.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_079.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_079.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_079.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_079.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_080.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_080.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_080.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_080.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_080.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_080.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_080.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_081.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_081.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_081.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_081.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_081.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_081.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_081.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_082.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_082.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_082.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_082.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_082.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_082.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_082.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_083.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_083.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_083.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_083.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_083.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_083.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_083.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_084.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_084.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_084.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_084.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_084.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_084.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_084.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_085.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_085.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_085.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_085.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_085.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_085.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_085.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_086.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_086.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_086.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_086.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_086.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_086.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_086.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_087.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_087.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_087.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_087.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_087.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_087.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_087.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_088.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_088.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_088.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_088.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_088.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_088.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_088.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_089.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_089.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_089.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_089.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_089.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_089.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_089.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_090.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_090.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_090.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_090.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_090.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_090.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_090.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_091.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_091.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_091.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_091.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_091.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_091.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_091.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_092.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_092.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_092.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_092.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_092.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_092.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_092.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_093.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_093.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_093.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_093.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_093.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_093.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_093.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_094.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_094.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_094.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_094.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_094.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_094.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_094.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_095.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_095.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_095.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_095.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_095.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_095.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_095.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_096.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_096.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_096.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_096.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_096.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_096.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_096.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_097.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_097.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_097.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_097.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_097.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_097.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_097.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_098.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_098.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_098.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_098.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_098.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_098.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_098.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_099.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_099.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_099.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_099.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_099.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_099.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_099.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_100.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_100.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_100.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_100.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_100.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_100.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_100.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_101.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_101.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_101.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_101.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_101.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_101.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_101.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_102.jpg",
                "faces": {},
                "height": 1067,
                "name": "kltreffen_2006_102.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_102.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x666_kltreffen_2006_102.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_102.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_102.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x1067_kltreffen_2006_102.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_103.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_103.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_103.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_103.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_103.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_103.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_103.jpg 800w"
                ],
                "width": 800
            },
            {
                "_thumb": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_104.jpg",
                "faces": {},
                "height": 600,
                "name": "kltreffen_2006_104.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_104.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_104.jpg 500w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_104.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_104.jpg 800w",
                    "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/800x600_kltreffen_2006_104.jpg 800w"
                ],
                "width": 800
            }
        ],
        "src": "/static/_gallery/albums/2006%20Klassentreffen%20Gro%C3%9Fglockner/500x375_kltreffen_2006_001.jpg"
    },
    "2014 Klasssentreffen 40 Jahre Friesach": {
        "name": "2014 Klasssentreffen 40 Jahre Friesach",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x330_DSC00713-cr.jpg",
                "faces": {},
                "height": 1269,
                "name": "DSC00713-cr.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1057_DSC00713-cr.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x330_DSC00713-cr.jpg 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x528_DSC00713-cr.jpg 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x676_DSC00713-cr.jpg 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1057_DSC00713-cr.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01118.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01118.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01118.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01118.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01118.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01118.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01118.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01119.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01119.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01119.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01119.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01119.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01119.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01119.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01120.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01120.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01120.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01120.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01120.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01120.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01120.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01121.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01121.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01121.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01121.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x1200_DSC01121.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x1536_DSC01121.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01121.JPG 1280w"
                ],
                "width": 1280
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01125.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01125.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01125.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01125.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01125.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01125.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01125.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01127.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01127.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01127.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01127.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01127.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01127.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01127.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01129.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01129.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01129.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01129.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01129.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01129.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01129.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01130.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01130.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01130.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01130.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01130.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01130.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01130.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01131.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01131.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01131.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01131.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01131.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01131.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01131.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01132.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01132.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01132.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01132.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01132.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01132.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01132.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01133.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01133.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01133.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01133.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01133.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01133.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01133.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01134.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01134.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01134.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01134.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01134.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01134.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01134.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01135.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01135.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01135.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01135.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01135.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01135.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01135.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01136.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01136.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01136.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01136.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01136.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01136.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01136.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01137.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01137.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01137.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01137.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01137.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01137.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01137.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01138.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01138.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01138.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01138.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01138.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01138.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01138.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01139.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01139.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01139.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01139.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01139.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01139.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01139.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01140.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01140.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01140.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01140.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01140.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01140.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01140.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01141.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01141.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01141.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01141.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01141.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01141.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01141.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01142.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01142.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01142.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01142.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01142.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01142.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01142.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01143.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01143.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01143.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01143.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01143.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01143.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01143.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01144.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01144.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01144.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01144.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01144.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01144.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01144.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01145.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01145.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01145.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01145.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01145.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01145.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01145.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01146.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01146.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01146.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01146.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01146.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01146.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01146.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01147.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01147.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01147.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01147.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01147.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01147.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01147.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01148.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01148.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01148.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01148.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01148.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01148.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01148.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01149.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01149.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01149.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01149.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01149.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01149.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01149.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01150.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01150.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01150.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01150.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01150.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01150.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01150.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01151.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01151.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01151.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01151.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x1200_DSC01151.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x1536_DSC01151.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01151.JPG 1280w"
                ],
                "width": 1280
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01152.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01152.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01152.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01152.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01152.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01152.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01152.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01153.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01153.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01153.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01153.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01153.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01153.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01153.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01154.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01154.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01154.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01154.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01154.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01154.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01154.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01155.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01155.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01155.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01155.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01155.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01155.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01155.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01156.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01156.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01156.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01156.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01156.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01156.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01156.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01157.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01157.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01157.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01157.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x1200_DSC01157.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x1536_DSC01157.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01157.JPG 1280w"
                ],
                "width": 1280
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01158.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01158.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01158.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01158.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01158.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01158.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01158.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01159.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01159.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01159.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01159.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01159.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01159.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01159.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01160.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01160.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01160.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01160.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01160.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01160.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01160.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01161.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01161.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01161.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01161.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01161.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01161.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01161.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01162.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01162.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01162.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01162.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01162.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01162.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01162.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01163.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01163.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01163.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01163.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01163.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01163.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01163.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01164.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01164.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01164.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01164.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01164.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01164.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01164.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01165.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01165.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01165.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01165.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01165.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01165.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01165.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01166.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01166.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01166.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01166.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x1200_DSC01166.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x1536_DSC01166.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01166.JPG 1280w"
                ],
                "width": 1280
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01167.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01167.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01167.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01167.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01167.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01167.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01167.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01168.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01168.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01168.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01168.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01168.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01168.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01168.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01171.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01171.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01171.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01171.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01171.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01171.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01171.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01173.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01173.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01173.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01173.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01173.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01173.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01173.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01175.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01175.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01175.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01175.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01175.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01175.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01175.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01176.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01176.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01176.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01176.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01176.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01176.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01176.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01178.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01178.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01178.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01178.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01178.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01178.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01178.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01179.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01179.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01179.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01179.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01179.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01179.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01179.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01180.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01180.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01180.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01180.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01180.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01180.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01180.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01181.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01181.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01181.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01181.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01181.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01181.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01181.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01182.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01182.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01182.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01182.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01182.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01182.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01182.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01183.JPG",
                "faces": {},
                "height": 1280,
                "name": "DSC01183.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01183.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x333_DSC01183.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x533_DSC01183.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x682_DSC01183.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1600x1066_DSC01183.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01184.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01184.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01184.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x750_DSC01184.JPG 500w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/800x1200_DSC01184.JPG 800w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1024x1536_DSC01184.JPG 1024w",
                    "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/1280x1920_DSC01184.JPG 1280w"
                ],
                "width": 1280
            }
        ],
        "src": "/static/_gallery/albums/2014%20Klasssentreffen%2040%20Jahre%20Friesach/500x330_DSC00713-cr.jpg"
    },
    "2018 Klassentreffen Klingenbach Rust M\u00f6rbisch Sopron Eisenstadt": {
        "name": "2018 Klassentreffen Klingenbach Rust M\u00f6rbisch Sopron Eisenstadt",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_10a%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "10a (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_10a%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_10a%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_10a%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_10a%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_10a%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_20%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "20 (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_20%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_20%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_20%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_20%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_20%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_22%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "22 (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_22%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_22%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_22%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_22%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_22%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_24%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "24 (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_24%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_24%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_24%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_24%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_24%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_4%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "4 (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_4%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_4%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_4%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_4%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_4%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_5%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "5 (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_5%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_5%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_5%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_5%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_5%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_8%20%28003%29.JPG",
                "faces": {},
                "height": 768,
                "name": "8 (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_8%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_8%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_8%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_8%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_8%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_9a%20%28003%29.JPG",
                "faces": {},
                "height": 576,
                "name": "9a (003).JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_9a%20%28003%29.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_9a%20%28003%29.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_9a%20%28003%29.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_9a%20%28003%29.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_9a%20%28003%29.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0120.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0120.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0120.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0120.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0120.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0120.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0120.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0130.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0130.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0130.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0130.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0130.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0130.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0130.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0131.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0131.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0131.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0131.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0131.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0131.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0131.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0136.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0136.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0136.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0136.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0136.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0136.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0136.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0137.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0137.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0137.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0137.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0137.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0137.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0137.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0139.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0139.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0139.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0139.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0139.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0139.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0139.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0143.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0143.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0143.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0143.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0143.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0143.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0143.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0144.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0144.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0144.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0144.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0144.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0144.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0144.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0147.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0147.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0147.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0147.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0147.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0147.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0147.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0149.JPG",
                "faces": {},
                "height": 1440,
                "name": "DSC_0149.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0149.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x375_DSC_0149.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x600_DSC_0149.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x768_DSC_0149.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1200_DSC_0149.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0526.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0526.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0526.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0526.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0526.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0526.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0526.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0532.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0532.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0532.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0532.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0532.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0532.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0532.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0534.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0534.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0534.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0534.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0534.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0534.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0534.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0538.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0538.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0538.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0538.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0538.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0538.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0538.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0541.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0541.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0541.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0541.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0541.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0541.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0541.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0548.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0548.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0548.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0548.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0548.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0548.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0548.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0552.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0552.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0552.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0552.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0552.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0552.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0552.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0555.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0555.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0555.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0555.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0555.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0555.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0555.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0557.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0557.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0557.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0557.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0557.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0557.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0557.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0559.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0559.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0559.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0559.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0559.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0559.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0559.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0561.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0561.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0561.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0561.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0561.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0561.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0561.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0563.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0563.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0563.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0563.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0563.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0563.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0563.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0564.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0564.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0564.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0564.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0564.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0564.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0564.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0565.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0565.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0565.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0565.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0565.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0565.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0565.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0567.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0567.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0567.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0567.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0567.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0567.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0567.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0568.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0568.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0568.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0568.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0568.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0568.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0568.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0573.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0573.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0573.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0573.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0573.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0573.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0573.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0574.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0574.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0574.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0574.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0574.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0574.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0574.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0579.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0579.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0579.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0579.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0579.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0579.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0579.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0580.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0580.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0580.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0580.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0580.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0580.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0580.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0584.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0584.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0584.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0584.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0584.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0584.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0584.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0594.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0594.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0594.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0594.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0594.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0594.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0594.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0606.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0606.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0606.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0606.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0606.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0606.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0606.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0607.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0607.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0607.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0607.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0607.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0607.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0607.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0608.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0608.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0608.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0608.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0608.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0608.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0608.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0611.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0611.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0611.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0611.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0611.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0611.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0611.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0612.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0612.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0612.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0612.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0612.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0612.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0612.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0613.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0613.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0613.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0613.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0613.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0613.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0613.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0614.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0614.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0614.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0614.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0614.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0614.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0614.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0616.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0616.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0616.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0616.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0616.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0616.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0616.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0622.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0622.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0622.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0622.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0622.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0622.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0622.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0624.JPG",
                "faces": {},
                "height": 1080,
                "name": "DSC_0624.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0624.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_DSC_0624.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x450_DSC_0624.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x576_DSC_0624.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x900_DSC_0624.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x500_SAM_0704.JPG",
                "faces": {},
                "height": 1920,
                "name": "SAM_0704.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1600_SAM_0704.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x500_SAM_0704.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x800_SAM_0704.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x1024_SAM_0704.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1600_SAM_0704.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x500_SAM_0705.JPG",
                "faces": {},
                "height": 1920,
                "name": "SAM_0705.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1600_SAM_0705.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x500_SAM_0705.JPG 500w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/800x800_SAM_0705.JPG 800w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1024x1024_SAM_0705.JPG 1024w",
                    "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/1600x1600_SAM_0705.JPG 1600w"
                ],
                "width": 1920
            }
        ],
        "src": "/static/_gallery/albums/2018%20Klassentreffen%20Klingenbach%20Rust%20M%C3%B6rbisch%20Sopron%20Eisenstadt/500x281_10a%20%28003%29.JPG"
    },
    "2020 Josephinum und Pr\u00fcfanstalt": {
        "name": "2020 Josephinum und Pr\u00fcfanstalt",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01552.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01552.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01552.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01552.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01552.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01552.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01552.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01553.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01553.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01553.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01553.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01553.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01553.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01553.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01555.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01555.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01555.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01555.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01555.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01555.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01555.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01556.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01556.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01556.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01556.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01556.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01556.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01556.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01557.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01557.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01557.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01557.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01557.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01557.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01557.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01558.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01558.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01558.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01558.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01558.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01558.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01558.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01559.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01559.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01559.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01559.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01559.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01559.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01559.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01560.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01560.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01560.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01560.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01560.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01560.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01560.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01561.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01561.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01561.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01561.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01561.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01561.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01561.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01562.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01562.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01562.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01562.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01562.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01562.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01562.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01563.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01563.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01563.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01563.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01563.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01563.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01563.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01564.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01564.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01564.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01564.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01564.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01564.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01564.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01565.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01565.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01565.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01565.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01565.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01565.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01565.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01566.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01566.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01566.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01566.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01566.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01566.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01566.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01567.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01567.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01567.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01567.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01567.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01567.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01567.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01569.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01569.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01569.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01569.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01569.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01569.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01569.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01570.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01570.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01570.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01570.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01570.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01570.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01570.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01571.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01571.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01571.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01571.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01571.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01571.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01571.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01572.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01572.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01572.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01572.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01572.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01572.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01572.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01573.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01573.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01573.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01573.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01573.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01573.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01573.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01574.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01574.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01574.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01574.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01574.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01574.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01574.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01575.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01575.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01575.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01575.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01575.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01575.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01575.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01576.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01576.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01576.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01576.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01576.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01576.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01576.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01577.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01577.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01577.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01577.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01577.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01577.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01577.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01578.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01578.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01578.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01578.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01578.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01578.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01578.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01579.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01579.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01579.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01579.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01579.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01579.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01579.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01580.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01580.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01580.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01580.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01580.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01580.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01580.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01581.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01581.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01581.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01581.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01581.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01581.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01581.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01582.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01582.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01582.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01582.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01582.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01582.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01582.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01583.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01583.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01583.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01583.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01583.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01583.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01583.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01584.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01584.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01584.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01584.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01584.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01584.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01584.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01585.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01585.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01585.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01585.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01585.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01585.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01585.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01586.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01586.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01586.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01586.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01586.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01586.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01586.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01587.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01587.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01587.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01587.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01587.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01587.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01587.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01588.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01588.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01588.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01588.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01588.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01588.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01588.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01589.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01589.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01589.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01589.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01589.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01589.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01589.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01590.JPG",
                "faces": {},
                "height": 1920,
                "name": "DSC01590.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01590.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x747_DSC01590.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x1196_DSC01590.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x1531_DSC01590.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1284x1920_DSC01590.JPG 1284w"
                ],
                "width": 1284
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01591.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01591.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01591.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01591.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01591.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01591.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01591.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01592.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01592.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01592.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01592.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01592.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01592.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01592.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01593.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01593.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01593.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01593.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01593.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01593.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01593.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01594.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01594.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01594.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01594.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01594.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01594.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01594.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01595.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01595.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01595.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01595.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01595.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01595.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01595.JPG 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01596.JPG",
                "faces": {},
                "height": 1284,
                "name": "DSC01596.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01596.JPG",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01596.JPG 500w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/800x535_DSC01596.JPG 800w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1024x684_DSC01596.JPG 1024w",
                    "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/1600x1070_DSC01596.JPG 1600w"
                ],
                "width": 1920
            }
        ],
        "src": "/static/_gallery/albums/2020%20Josephinum%20und%20Pr%C3%BCfanstalt/500x334_DSC01552.JPG"
    },
    "2020 Klassentreffen Brauhof": {
        "name": "2020 Klassentreffen Brauhof",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_174832.jpg",
                "faces": {},
                "height": 1440,
                "name": "IMG_20200911_174832.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_174832.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_174832.jpg 500w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/800x600_IMG_20200911_174832.jpg 800w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1024x768_IMG_20200911_174832.jpg 1024w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_174832.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_191121.jpg",
                "faces": {},
                "height": 1440,
                "name": "IMG_20200911_191121.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_191121.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_191121.jpg 500w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/800x600_IMG_20200911_191121.jpg 800w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1024x768_IMG_20200911_191121.jpg 1024w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_191121.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_191129.jpg",
                "faces": {},
                "height": 1440,
                "name": "IMG_20200911_191129.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_191129.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_191129.jpg 500w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/800x600_IMG_20200911_191129.jpg 800w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1024x768_IMG_20200911_191129.jpg 1024w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_191129.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_191140.jpg",
                "faces": {},
                "height": 1440,
                "name": "IMG_20200911_191140.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_191140.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_191140.jpg 500w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/800x600_IMG_20200911_191140.jpg 800w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1024x768_IMG_20200911_191140.jpg 1024w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1600x1200_IMG_20200911_191140.jpg 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x666_IMG_20200912_010644.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200912_010644.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1440x1920_IMG_20200912_010644.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x666_IMG_20200912_010644.jpg 500w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/800x1066_IMG_20200912_010644.jpg 800w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1024x1365_IMG_20200912_010644.jpg 1024w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1440x1920_IMG_20200912_010644.jpg 1440w"
                ],
                "width": 1440
            },
            {
                "_thumb": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x666_IMG_20200912_012137.jpg",
                "faces": {},
                "height": 1920,
                "name": "IMG_20200912_012137.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1440x1920_IMG_20200912_012137.jpg",
                "srcSet": [
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x666_IMG_20200912_012137.jpg 500w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/800x1066_IMG_20200912_012137.jpg 800w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1024x1365_IMG_20200912_012137.jpg 1024w",
                    "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/1440x1920_IMG_20200912_012137.jpg 1440w"
                ],
                "width": 1440
            }
        ],
        "src": "/static/_gallery/albums/2020%20Klassentreffen%20Brauhof/500x375_IMG_20200911_174832.jpg"
    },
    "Andere": {
        "name": "Andere",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/Andere/500x331_60er-Pauschi_DSC7248.JPG",
                "faces": {},
                "height": 2368,
                "name": "60er-Pauschi_DSC7248.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x1061_60er-Pauschi_DSC7248.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x331_60er-Pauschi_DSC7248.JPG 500w",
                    "/static/_gallery/albums/Andere/800x530_60er-Pauschi_DSC7248.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x679_60er-Pauschi_DSC7248.JPG 1024w",
                    "/static/_gallery/albums/Andere/1600x1061_60er-Pauschi_DSC7248.JPG 1600w"
                ],
                "width": 3568
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x331_60er-Pauschi_DSC7263.JPG",
                "faces": {},
                "height": 2368,
                "name": "60er-Pauschi_DSC7263.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x1061_60er-Pauschi_DSC7263.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x331_60er-Pauschi_DSC7263.JPG 500w",
                    "/static/_gallery/albums/Andere/800x530_60er-Pauschi_DSC7263.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x679_60er-Pauschi_DSC7263.JPG 1024w",
                    "/static/_gallery/albums/Andere/1600x1061_60er-Pauschi_DSC7263.JPG 1600w"
                ],
                "width": 3568
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x375_Ambros-Maturatreffen2020.jpg",
                "faces": {},
                "height": 480,
                "name": "Ambros-Maturatreffen2020.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/640x480_Ambros-Maturatreffen2020.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x375_Ambros-Maturatreffen2020.jpg 500w",
                    "/static/_gallery/albums/Andere/640x480_Ambros-Maturatreffen2020.jpg 640w",
                    "/static/_gallery/albums/Andere/640x480_Ambros-Maturatreffen2020.jpg 640w",
                    "/static/_gallery/albums/Andere/640x480_Ambros-Maturatreffen2020.jpg 640w"
                ],
                "width": 640
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x299_Fuzzy-Edlinger_cr.jpg",
                "faces": {},
                "height": 436,
                "name": "Fuzzy-Edlinger_cr.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/728x436_Fuzzy-Edlinger_cr.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x299_Fuzzy-Edlinger_cr.jpg 500w",
                    "/static/_gallery/albums/Andere/728x436_Fuzzy-Edlinger_cr.jpg 728w",
                    "/static/_gallery/albums/Andere/728x436_Fuzzy-Edlinger_cr.jpg 728w",
                    "/static/_gallery/albums/Andere/728x436_Fuzzy-Edlinger_cr.jpg 728w"
                ],
                "width": 728
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x375_Jagdliches-Klassentreffen2018-Dobratsch.jpg",
                "faces": {},
                "height": 480,
                "name": "Jagdliches-Klassentreffen2018-Dobratsch.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/640x480_Jagdliches-Klassentreffen2018-Dobratsch.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x375_Jagdliches-Klassentreffen2018-Dobratsch.jpg 500w",
                    "/static/_gallery/albums/Andere/640x480_Jagdliches-Klassentreffen2018-Dobratsch.jpg 640w",
                    "/static/_gallery/albums/Andere/640x480_Jagdliches-Klassentreffen2018-Dobratsch.jpg 640w",
                    "/static/_gallery/albums/Andere/640x480_Jagdliches-Klassentreffen2018-Dobratsch.jpg 640w"
                ],
                "width": 640
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x666_Reichmann-2.JPG",
                "faces": {},
                "height": 1600,
                "name": "Reichmann-2.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1200x1600_Reichmann-2.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x666_Reichmann-2.JPG 500w",
                    "/static/_gallery/albums/Andere/800x1066_Reichmann-2.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x1365_Reichmann-2.JPG 1024w",
                    "/static/_gallery/albums/Andere/1200x1600_Reichmann-2.JPG 1200w"
                ],
                "width": 1200
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x500_SAM_0702.JPG",
                "faces": {},
                "height": 2832,
                "name": "SAM_0702.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x1600_SAM_0702.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x500_SAM_0702.JPG 500w",
                    "/static/_gallery/albums/Andere/800x800_SAM_0702.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x1024_SAM_0702.JPG 1024w",
                    "/static/_gallery/albums/Andere/1600x1600_SAM_0702.JPG 1600w"
                ],
                "width": 2832
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x340_Verabschiedung-Ernst-Pauschenwein-1.jpg",
                "faces": {},
                "height": 2363,
                "name": "Verabschiedung-Ernst-Pauschenwein-1.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x1088_Verabschiedung-Ernst-Pauschenwein-1.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x340_Verabschiedung-Ernst-Pauschenwein-1.jpg 500w",
                    "/static/_gallery/albums/Andere/800x544_Verabschiedung-Ernst-Pauschenwein-1.jpg 800w",
                    "/static/_gallery/albums/Andere/1024x696_Verabschiedung-Ernst-Pauschenwein-1.jpg 1024w",
                    "/static/_gallery/albums/Andere/1600x1088_Verabschiedung-Ernst-Pauschenwein-1.jpg 1600w"
                ],
                "width": 3475
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x375_Verabschiedung-Ernst-Pauschenwein-2.jpg",
                "faces": {},
                "height": 3060,
                "name": "Verabschiedung-Ernst-Pauschenwein-2.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x1200_Verabschiedung-Ernst-Pauschenwein-2.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x375_Verabschiedung-Ernst-Pauschenwein-2.jpg 500w",
                    "/static/_gallery/albums/Andere/800x600_Verabschiedung-Ernst-Pauschenwein-2.jpg 800w",
                    "/static/_gallery/albums/Andere/1024x768_Verabschiedung-Ernst-Pauschenwein-2.jpg 1024w",
                    "/static/_gallery/albums/Andere/1600x1200_Verabschiedung-Ernst-Pauschenwein-2.jpg 1600w"
                ],
                "width": 4080
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x297_Verabschiedung-Ernst-Pauschenwein-3.jpg",
                "faces": {},
                "height": 2041,
                "name": "Verabschiedung-Ernst-Pauschenwein-3.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x951_Verabschiedung-Ernst-Pauschenwein-3.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x297_Verabschiedung-Ernst-Pauschenwein-3.jpg 500w",
                    "/static/_gallery/albums/Andere/800x475_Verabschiedung-Ernst-Pauschenwein-3.jpg 800w",
                    "/static/_gallery/albums/Andere/1024x608_Verabschiedung-Ernst-Pauschenwein-3.jpg 1024w",
                    "/static/_gallery/albums/Andere/1600x951_Verabschiedung-Ernst-Pauschenwein-3.jpg 1600w"
                ],
                "width": 3433
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x303_Verabschiedung-Ernst-Pauschenwein-4.jpg",
                "faces": {},
                "height": 2150,
                "name": "Verabschiedung-Ernst-Pauschenwein-4.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x972_Verabschiedung-Ernst-Pauschenwein-4.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x303_Verabschiedung-Ernst-Pauschenwein-4.jpg 500w",
                    "/static/_gallery/albums/Andere/800x486_Verabschiedung-Ernst-Pauschenwein-4.jpg 800w",
                    "/static/_gallery/albums/Andere/1024x622_Verabschiedung-Ernst-Pauschenwein-4.jpg 1024w",
                    "/static/_gallery/albums/Andere/1600x972_Verabschiedung-Ernst-Pauschenwein-4.jpg 1600w"
                ],
                "width": 3537
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x375_Verabschiedung-Ernst-Pauschenwein-6.jpg",
                "faces": {},
                "height": 3060,
                "name": "Verabschiedung-Ernst-Pauschenwein-6.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1600x1200_Verabschiedung-Ernst-Pauschenwein-6.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x375_Verabschiedung-Ernst-Pauschenwein-6.jpg 500w",
                    "/static/_gallery/albums/Andere/800x600_Verabschiedung-Ernst-Pauschenwein-6.jpg 800w",
                    "/static/_gallery/albums/Andere/1024x768_Verabschiedung-Ernst-Pauschenwein-6.jpg 1024w",
                    "/static/_gallery/albums/Andere/1600x1200_Verabschiedung-Ernst-Pauschenwein-6.jpg 1600w"
                ],
                "width": 4080
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x789_Verabschiedung-Robert-Wimmer-DSC_0001.JPG",
                "faces": {},
                "height": 900,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0001.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/570x900_Verabschiedung-Robert-Wimmer-DSC_0001.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x789_Verabschiedung-Robert-Wimmer-DSC_0001.JPG 500w",
                    "/static/_gallery/albums/Andere/570x900_Verabschiedung-Robert-Wimmer-DSC_0001.JPG 570w",
                    "/static/_gallery/albums/Andere/570x900_Verabschiedung-Robert-Wimmer-DSC_0001.JPG 570w",
                    "/static/_gallery/albums/Andere/570x900_Verabschiedung-Robert-Wimmer-DSC_0001.JPG 570w"
                ],
                "width": 570
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0002.JPG",
                "faces": {},
                "height": 576,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0002.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0002.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0002.JPG 500w",
                    "/static/_gallery/albums/Andere/800x450_Verabschiedung-Robert-Wimmer-DSC_0002.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0002.JPG 1024w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0002.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0003.JPG",
                "faces": {},
                "height": 576,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0003.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0003.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0003.JPG 500w",
                    "/static/_gallery/albums/Andere/800x450_Verabschiedung-Robert-Wimmer-DSC_0003.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0003.JPG 1024w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0003.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0005.JPG",
                "faces": {},
                "height": 576,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0005.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0005.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0005.JPG 500w",
                    "/static/_gallery/albums/Andere/800x450_Verabschiedung-Robert-Wimmer-DSC_0005.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0005.JPG 1024w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0005.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0006.JPG",
                "faces": {},
                "height": 576,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0006.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0006.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0006.JPG 500w",
                    "/static/_gallery/albums/Andere/800x450_Verabschiedung-Robert-Wimmer-DSC_0006.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0006.JPG 1024w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0006.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0007.JPG",
                "faces": {},
                "height": 576,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0007.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0007.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0007.JPG 500w",
                    "/static/_gallery/albums/Andere/800x450_Verabschiedung-Robert-Wimmer-DSC_0007.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0007.JPG 1024w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0007.JPG 1024w"
                ],
                "width": 1024
            },
            {
                "_thumb": "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0008.JPG",
                "faces": {},
                "height": 576,
                "name": "Verabschiedung-Robert-Wimmer-DSC_0008.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0008.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Andere/500x281_Verabschiedung-Robert-Wimmer-DSC_0008.JPG 500w",
                    "/static/_gallery/albums/Andere/800x450_Verabschiedung-Robert-Wimmer-DSC_0008.JPG 800w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0008.JPG 1024w",
                    "/static/_gallery/albums/Andere/1024x576_Verabschiedung-Robert-Wimmer-DSC_0008.JPG 1024w"
                ],
                "width": 1024
            }
        ],
        "src": "/static/_gallery/albums/Andere/500x331_60er-Pauschi_DSC7248.JPG"
    },
    "Parten": {
        "name": "Parten",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/Parten/500x706_089_Parte_Ernst_Pauschenwein.jpg",
                "faces": {},
                "height": 1753,
                "name": "089_Parte_Ernst_Pauschenwein.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Parten/1240x1753_089_Parte_Ernst_Pauschenwein.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Parten/500x706_089_Parte_Ernst_Pauschenwein.jpg 500w",
                    "/static/_gallery/albums/Parten/800x1130_089_Parte_Ernst_Pauschenwein.jpg 800w",
                    "/static/_gallery/albums/Parten/1024x1447_089_Parte_Ernst_Pauschenwein.jpg 1024w",
                    "/static/_gallery/albums/Parten/1240x1753_089_Parte_Ernst_Pauschenwein.jpg 1240w"
                ],
                "width": 1240
            },
            {
                "_thumb": "/static/_gallery/albums/Parten/499x707_090_Parte_Robert_Wimmer.jpg",
                "faces": {},
                "height": 1536,
                "name": "090_Parte_Robert_Wimmer.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Parten/1086x1536_090_Parte_Robert_Wimmer.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Parten/499x707_090_Parte_Robert_Wimmer.jpg 499w",
                    "/static/_gallery/albums/Parten/800x1131_090_Parte_Robert_Wimmer.jpg 800w",
                    "/static/_gallery/albums/Parten/1024x1448_090_Parte_Robert_Wimmer.jpg 1024w",
                    "/static/_gallery/albums/Parten/1086x1536_090_Parte_Robert_Wimmer.jpg 1086w"
                ],
                "width": 1086
            },
            {
                "_thumb": "/static/_gallery/albums/Parten/500x707_093_Parte_Anton_Stelzhammer.jpg",
                "faces": {},
                "height": 2526,
                "name": "093_Parte_Anton_Stelzhammer.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Parten/1599x2264_093_Parte_Anton_Stelzhammer.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Parten/500x707_093_Parte_Anton_Stelzhammer.jpg 500w",
                    "/static/_gallery/albums/Parten/799x1132_093_Parte_Anton_Stelzhammer.jpg 799w",
                    "/static/_gallery/albums/Parten/1024x1449_093_Parte_Anton_Stelzhammer.jpg 1024w",
                    "/static/_gallery/albums/Parten/1599x2264_093_Parte_Anton_Stelzhammer.jpg 1599w"
                ],
                "width": 1785
            },
            {
                "_thumb": "/static/_gallery/albums/Parten/500x720_095_Parte_Franz_Strassbauer.jpg",
                "faces": {},
                "height": 1146,
                "name": "095_Parte_Franz_Strassbauer.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Parten/795x1146_095_Parte_Franz_Strassbauer.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Parten/500x720_095_Parte_Franz_Strassbauer.jpg 500w",
                    "/static/_gallery/albums/Parten/795x1146_095_Parte_Franz_Strassbauer.jpg 795w",
                    "/static/_gallery/albums/Parten/795x1146_095_Parte_Franz_Strassbauer.jpg 795w",
                    "/static/_gallery/albums/Parten/795x1146_095_Parte_Franz_Strassbauer.jpg 795w"
                ],
                "width": 795
            },
            {
                "_thumb": "/static/_gallery/albums/Parten/500x718_099_Parte_Leopold_Makoschitz.jpg",
                "faces": {},
                "height": 1146,
                "name": "099_Parte_Leopold_Makoschitz.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Parten/798x1146_099_Parte_Leopold_Makoschitz.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Parten/500x718_099_Parte_Leopold_Makoschitz.jpg 500w",
                    "/static/_gallery/albums/Parten/798x1146_099_Parte_Leopold_Makoschitz.jpg 798w",
                    "/static/_gallery/albums/Parten/798x1146_099_Parte_Leopold_Makoschitz.jpg 798w",
                    "/static/_gallery/albums/Parten/798x1146_099_Parte_Leopold_Makoschitz.jpg 798w"
                ],
                "width": 798
            },
            {
                "_thumb": "/static/_gallery/albums/Parten/500x707_100_Parte_Herman_Kr%C3%A4han.jpg",
                "faces": {},
                "height": 3367,
                "name": "100_Parte_Herman_Kr\u00e4han.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Parten/1600x2262_100_Parte_Herman_Kr%C3%A4han.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Parten/500x707_100_Parte_Herman_Kr%C3%A4han.jpg 500w",
                    "/static/_gallery/albums/Parten/800x1131_100_Parte_Herman_Kr%C3%A4han.jpg 800w",
                    "/static/_gallery/albums/Parten/1024x1448_100_Parte_Herman_Kr%C3%A4han.jpg 1024w",
                    "/static/_gallery/albums/Parten/1600x2262_100_Parte_Herman_Kr%C3%A4han.jpg 1600w"
                ],
                "width": 2381
            }
        ],
        "src": "/static/_gallery/albums/Parten/500x706_089_Parte_Ernst_Pauschenwein.jpg"
    }
};